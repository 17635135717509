import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { IconMail, IconPhone } from "../atoms/Icons";
import Layout from "../components/layout";
import Seo from "../components/seo";

const ContactPage = () => {
  return (
    <Layout>
      <Seo title="Contacto" />
      <div
        id="location"
        className="flex flex-col items-center lg:flex-row  mx-auto gap-8 lg:gap-8 lg:max-w-7xl py-16 lg:min-h-screen lg:-mt-40 lg:pt-40"
      >
        <div className="lg:w-1/2 space-y-8 ">
          <h2 className="title text-3xl">Hospedate en Villas Cheyos</h2>
          <p>
            {" "}
            Para llegar a Villas Cheyos, por tierra, toma de referencia la
            ciudad de Lázaro Cárdenas. Allí puedes tomarte un autobús hacia
            Barra de Nexpa.
          </p>

          <p>
            Si vienes en avión, desde el aeropuerto de Zihuatanejo encuentras
            autobuses a Barra de Nexpa, o podemos ocuparnos y coordinar un
            servicio de pick up hasta las cabañas.
          </p>
          <div className="flex flex-col lg:items-center gap-4 justify-between lg:flex-row pr-8">
            <div className="flex items-center gap-2">
              <IconMail />
              <p>cheyo_nexpa@hotmail.com</p>
            </div>
            <div className="flex items-center gap-2">
              <IconPhone />
              <a className="z-20" href="https://wa.me/527531103093">
                +52 753 110 3093
              </a>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 mx-auto xl:ml-auto">
          <a href="https://www.google.com/maps/place/VillasCheyos/@18.0873306,-102.7937807,17z/data=!4m5!3m4!1s0x84311e0f2ceb3011:0x75f2da2ea9a9540!8m2!3d18.087363!4d-102.7915482">
            <StaticImage
              width={560}
              src="../images/location.png"
              alt="location Nexpa"
              placeholder="tracedSVG"
              className="w-full rounded-lg shadow-lg ml-auto"
            />
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;
